import React, { useRef, useEffect, useState, useCallback } from "react";
import "./SearchBar.css";
import { useCartContext } from "../contexts/CartContext";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"; // Import js-cookie

function SearchBar() {
	const {
		query,
		setQuery,
		handleSearchQuery,
		handleSearchNameQuery,
		setResults,
	} = useCartContext();
	const { t } = useTranslation();
	const [searchType, setSearchType] = useState("mytecdoc");
	const [history, setHistory] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const inputRef = useRef(null);

	// Load search history from cookies on mount
	useEffect(() => {
		const storedHistory = Cookies.get("searchHistory");
		setHistory(storedHistory ? JSON.parse(storedHistory) : []);
	}, []);

	const placeholderText =
		t("searchby") +
		" " +
		(searchType === "mytecdoc" ? t("oemorpartnr") : t("productname"));

	const handleSearchClick = useCallback(() => {
		if (query.trim() === "") {
			setResults([]);
			return;
		}
		if (searchType === "mytecdoc") {
			handleSearchQuery(query);
		} else {
			handleSearchNameQuery(query);
		}

		// Update search history and save to cookies
		const updatedHistory = [
			query,
			...history.filter((item) => item !== query),
		].slice(0, 10);
		setHistory(updatedHistory);
		Cookies.set("searchHistory", JSON.stringify(updatedHistory), {
			expires: 7,
		}); // Save for 7 days
	}, [
		handleSearchQuery,
		handleSearchNameQuery,
		query,
		searchType,
		setResults,
		history,
	]);

	const handleSelectHistory = (selectedQuery) => {
		setQuery(selectedQuery);
		setShowDropdown(false);
		handleSearchClick(); // Trigger search immediately
	};

	const handleInputChange = (e) => {
		const inputText = e.target.value;
		const sanitizedQuery =
			searchType === "mytecdoc"
				? inputText.replace(/[^A-Za-z0-9\/.-]/gi, "")
				: inputText.replace(/[^A-Za-z0-9\s/.-]/gi, "");
		setQuery(sanitizedQuery);
		setShowDropdown(true);
	};

	const handleEnterKeyPress = (e) => {
		if (e.key === "Enter") {
			handleSearchClick();
			setShowDropdown(false);
		}
	};

	const handlePaste = (e) => {
		e.preventDefault();
		const pastedText = e.clipboardData.getData("text/plain");
		const sanitizedQuery =
			searchType === "mytecdoc"
				? pastedText.replace(/[^A-Za-z0-9\/.-]/gi, "")
				: pastedText.replace(/[^A-Za-z0-9\s/.-]/gi, "");
		setQuery(sanitizedQuery);
		setShowDropdown(true);
	};

	const handleSearchTypeChange = (e) => {
		setSearchType(e.target.value);
		//setQuery("");
		setShowDropdown(false);
	};

	useEffect(() => {
		//setQuery(sanitizedQuery);
		inputRef.current.focus();
	}, []);

	const searchbarclassname =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? "benfikosearchbar"
			: "autostarsearchbar";

	return (
		<form
			className={searchbarclassname}
			onSubmit={(e) => {
				e.preventDefault();
				handleSearchClick();
			}}
		>
			<div className="search-container">
				<input
					type="text"
					placeholder={placeholderText}
					className="search-input"
					value={query}
					onChange={handleInputChange}
					onPaste={handlePaste}
					onKeyDown={handleEnterKeyPress}
					onFocus={() => {
						setQuery(""); // Clear the input on focus
						setShowDropdown(true); // Optionally show the dropdown if desired
					}}
					onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
					ref={inputRef}
					required
				/>
				{showDropdown && history.length > 0 && (
					<ul className="search-dropdown">
						{history.map((item, index) => (
							<li key={index} onMouseDown={() => handleSelectHistory(item)}>
								{item}
							</li>
						))}
					</ul>
				)}

				<div className="radio-group">
					<label className="radio-label">
						<input
							type="radio"
							name="option"
							value="mytecdoc"
							checked={searchType === "mytecdoc"}
							onChange={handleSearchTypeChange}
							className="radio-input"
						/>
						{t("oemorpartnr")}
					</label>
					<label className="radio-label">
						<input
							type="radio"
							name="option"
							value="productname"
							checked={searchType === "productname"}
							onChange={handleSearchTypeChange}
							className="radio-input"
						/>
						{t("productname")}
					</label>
				</div>
			</div>
			<button type="submit" className="search-button">
				{t("search")}
			</button>
		</form>
	);
}

export default SearchBar;
