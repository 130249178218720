import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Home from '../../pages/Home';
import History from '../../pages/History';
import AboutUs from '../../pages/AboutUs';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './routes.css';
import TrackOrder from '../../pages/TrackOrder';
import { useTranslation } from 'react-i18next';
import ShopingCartPage from '../../pages/ShopingCartPage';



function AppRoutes() {
  const { t } = useTranslation();
  return (
    <Router >
      <Navbar className="menunavbar" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/" className={({ isActive }) => (isActive ? "active" : "")}>{t("home")}</Nav.Link>
              <Nav.Link as={NavLink} to="/history" className={({ isActive }) => (isActive ? "active" : "")}>{t("history")}</Nav.Link>
              <Nav.Link as={NavLink} to="/track-orders" className={({ isActive }) => (isActive ? "active" : "")}>{t("trackorders")}</Nav.Link>
              <Nav.Link as={NavLink} to="/about-us" className={({ isActive }) => (isActive ? "active" : "")}>{t("aboutus")}</Nav.Link>
             
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="content-and-search">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/track-orders" element={<TrackOrder />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/cart" element={<ShopingCartPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default AppRoutes;
