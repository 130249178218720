import React, { useState, useEffect } from "react";
import "./topfooter.css"; // Import the CSS file
import { checkCartChange } from "../../services/ApiService";
import axiosInstance from "../../services/axiosInstance";
import { t } from "i18next";

const TopFooter = () => {
	const [trackingOrderChanged, setTrackingOrderChanged] = useState(null);
	const [lastActiveTime, setLastActiveTime] = useState(
		localStorage.getItem("lastActiveTime")
	);
	const [dbConnectionLost, setDbConnectionLost] = useState(false);
	const [sseActive, setSseActive] = useState(false);

	// Function to fetch last order data
	const getLastOrder = async () => {
		const userId = localStorage.getItem("customerid"); // Get user ID dynamically
		if (!userId) return;

		try {
			const response = await axiosInstance.get(checkCartChange + userId);
			const lastOrderResponse = response.data.map((it) => ({
				oem_nr: it.upcsku,
				vend_nr: it.vendoritemnumber,
				emri: it.productname,
				quantity: it.quantity,
				quantity_sent: it.quantity_sent || it.quantity,
				id_dealersorders: it.id_dealersorders,
			}));

			setTrackingOrderChanged(
				lastOrderResponse.length ? lastOrderResponse : null
			);
			console.log("Tracking order updated");
		} catch (error) {
			console.error("Error fetching order data:", error);
			setTrackingOrderChanged(null);
		}
	};

	// Effect to check localStorage updates for order tracking
	useEffect(() => {
		const checkLastActiveTime = () => {
			const newLastActiveTime = localStorage.getItem("lastActiveTime");
			if (newLastActiveTime !== lastActiveTime) {
				setLastActiveTime(newLastActiveTime);
				getLastOrder();
			}
		};

		const interval = setInterval(checkLastActiveTime, 1000); // Check every second

		return () => clearInterval(interval); // Cleanup interval on unmount
	}, [lastActiveTime]);

// Effect to monitor database connection status and fetch orders if lost

useEffect(() => {
  const checkDatabaseConnection = () => {
    const sseMessage = localStorage.getItem("sseMessage");
    const isDbLost = sseMessage === "No database connection";
    setDbConnectionLost(isDbLost);
    setSseActive(isDbLost);
  };

  checkDatabaseConnection(); // Run initially

  const interval = setInterval(checkDatabaseConnection, 1000); // Monitor sseMessage

  return () => clearInterval(interval); // Cleanup interval on unmount
}, []);

// Effect to fetch orders every second if database connection is lost
/*
useEffect(() => {
  if (!dbConnectionLost) return;

  const interval = setInterval(getLastOrder, 1000);

  return () => clearInterval(interval);
}, [dbConnectionLost]);

*/
	if (dbConnectionLost) {
		return (
			<footer className="top-footer-noconnection">
				<div className="top-footer-text-noconnection">
					<p className="scroll-text-noconnection">
						{t("connectionlost")}
					</p>
				</div>
			</footer>
		);
	}

	if (!trackingOrderChanged) {
		return null;
	}

	return (
		<footer className="top-footer">
			<div className="top-footer-text">
				{trackingOrderChanged.map((item, index) => (
					<p className="scroll-text" key={index}>{`${t("productname")}: ${
						item.emri
					}, ${t("oemnumber")}: ${item.oem_nr}, ${t("articlenumber")}: ${
						item.vend_nr
					}, ${t("quantityordered")}: ${item.quantity}, ${t("QuantitySent")}: ${
						item.quantity_sent
					}`}</p>
				))}
			</div>
		</footer>
	);
};

export default TopFooter;
