import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useRef } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import LanguageToggle from "../components/translations/LanguageToggle";
//import logoImage from "../img/logo1.png";
import axios from "axios";
import {dealerordersURL,firmaURL,loginURL,} from "../services/ApiService";
import { useCartContext } from "../contexts/CartContext";
import { encode } from "base-64";
//import SignOutInactiveUser from "../components/inactivitycheck/checkinactivity";
import { getLastOrder } from "../contexts/CartContextFunctions";
import axiosInstance from "../services/axiosInstance";

/*
const baseserver=process.env.REACT_APP_BASESERVER;
const logoPath=process.env.REACT_APP_LOGOPATH;
const loginURL=`${baseserver}/customers/login/`;
const dealerordersURL=`${baseserver}/orders`;
const firmaURL=`${baseserver}/firma`

*/

const logoPath =
	process.env.REACT_APP_APP_NAME === "benfiko"
		? "/image/logo/benfikoLogoSignIn.png"
		: "/image/logo/autostarLogoBlack.png";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" href="https://tetnet-pro.com/">
				Tetnet Pro Computers
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const defaultTheme = createTheme();

export default function SignIn() {
	const { Login } = useAuthContext();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const usernameRef = useRef("");
	const passwordRef = useRef("");
	const {
		setCartItems,
	} = useCartContext();
	const { t } = useTranslation();

	const HandleUsernameChange = (event) => {
		setUsername(event.target.value);
		usernameRef.current = event.target.value; // Store value in ref
	};

	const HandlePasswordChange = (event) => {
		setPassword(event.target.value);
		passwordRef.current = event.target.value; // Store value in ref
	};



	const HandleLogin = async (event) => {

		event.preventDefault(); // Prevent default form submission

		try {
			const encrypdetusername = encode(usernameRef.current);
			const encryptedpassword = encode(passwordRef.current);

			const postData = {
				username: encrypdetusername,
				pass: encryptedpassword,
			};
			const response = await axios.post(loginURL, postData);

			if (response.data.token) {
				const data = response.data;
				const token = data.token;
				const ipAdresa = data.ipAddress;
				localStorage.setItem("token", token);
				localStorage.setItem("IpAdresa", ipAdresa ?? "127.0.0.1");
				const checktoken = localStorage.getItem("checktoken") || "";
				if (!checktoken) {
					localStorage.setItem("checktoken", token);
					localStorage.setItem("customerid", data.user.id || "");
					localStorage.setItem("companyname", data.user.company);
				
				}

				await axiosInstance.get(firmaURL).then(function (response) {
					const firmaEmri = response.data;
					localStorage.setItem("firmaemri", firmaEmri[0].emri);
				});

				const getdealersOrdersId = `${dealerordersURL}/-1/${data.user.id}/0/-1/-1`;

				await axiosInstance
					.get(getdealersOrdersId)
					.then(function (response) {
						if (response.data && response.data.length > 0) {
							const dealerOrdersId = response.data[0].id;
							localStorage.setItem("id_dealersorders", dealerOrdersId);
						} else {
							console.log("No data available. Continuing...");
						}
					})
					.catch(function (error) {
						console.error("Error fetching data:", error);
					});

				getLastOrder(data.user.id, setCartItems);
		
				Login();

			} else {
				alert("Your username or password did not match.");
			}
		} catch (error) {
			alert("An error occurred while logging in.");
		}
	};

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" maxWidth="xs">
				<LanguageToggle />
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<img src={process.env.PUBLIC_URL + logoPath} alt="Logo" />
					<Typography component="h1" variant="h5">
						{t("signin")}
					</Typography>
					<Box
						component="form"
						onSubmit={HandleLogin}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="usernamе"
							label={t("username")}
							name="username"
							autoComplete="username"
							autoFocus
							value={username}
							onChange={HandleUsernameChange}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label={t("password")}
							type="password"
							id="password"
							autoComplete="current-password"
							value={password}
							onChange={HandlePasswordChange}
						/>
						<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label={t("rememberme")}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							{t("signin")}
						</Button>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</ThemeProvider>
	);
}
