import React, { useState, createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import useSSE from "../services/useSSE";

const AuthContext = createContext();

const useAuthContext = () => {
	return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loggedIn, setLoggedin] = useState(false);
	const [userId, setUserId] = useState();
	const token = localStorage.getItem("token");
	const tokentest = localStorage.getItem("checktoken");
	const clientId = localStorage.getItem("customerid"); // Retrieve clientId

	// Use the custom SSE hook
	const { message, isConnected, resetConnection } = useSSE(clientId);

	// Auto-connect SSE only when logged in
	/*
	useEffect(() => {
		if (!loggedIn) {
			resetConnection(); // Disconnect SSE on logout
		}
	}, [loggedIn]);
*/
	const Login = () => {
		setLoggedin(true);
	};

	const Logout = () => {
		resetConnection(); // Close SSE on logout
		localStorage.removeItem("customerid");
		localStorage.removeItem("companyname");
		localStorage.removeItem("isdealer");
		localStorage.removeItem("historyorderstorage");
		localStorage.removeItem("historycartstorage");
		localStorage.removeItem("checktoken");
		localStorage.removeItem("IpAdresa");
		localStorage.removeItem("firmaemri");
		localStorage.removeItem("id_dealersorders");
		setLoggedin(false);
	};

	// Auto-login if token exists
	useEffect(() => {
		if (token) {
			setLoggedin(true);
		}
	}, []);

	// Periodic check if token has changed
	useEffect(() => {
		const fetchData = () => {
			const token = localStorage.getItem("token");
			const tokentest = localStorage.getItem("checktoken");
			if (token !== tokentest) {
				Logout();
			}
		};

		fetchData();
		const intervalId = setInterval(fetchData, 1800);

		return () => clearInterval(intervalId);
	}, [tokentest, token]);

	return (
		<AuthContext.Provider
			value={{
				setUserId,
				userId,
				loggedIn,
				Login,
				Logout,
				username,
				setUsername,
				password,
				setPassword,
				message, // SSE message
				isConnected, // SSE connection status
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node,
};

export { AuthProvider, AuthContext, useAuthContext };
