import { useEffect } from "react";
import axiosInstance from "../../services/axiosInstance";
import { versionLogURL } from "../../services/ApiService";

function useVersionChecker() {
  // Function to send version log to the backend
  const sendVersionLog = async (version) => {
    try {
      const appId = localStorage.getItem("customerid");
      if (!appId) {
        console.error("Missing customer ID");
        return;
      }

      await axiosInstance.post(versionLogURL, { id: appId, version });
      console.log("Version log sent:", { id: appId, version });
    } catch (error) {
      console.error("Failed to send version log", error);
    }
  };

  useEffect(() => {
    const checkForUpdate = async () => {
      try {
        const appId = localStorage.getItem("customerid");
        if (!appId) {
          console.error("Skipping version check: No customer ID found.");
          return;
        }

        const response = await fetch("/version.json");
        const data = await response.json();

        const currentVersion = data.version;
        const storedVersion = localStorage.getItem("appVersion");

        // Send version log only if the version has changed
        if (!storedVersion || storedVersion !== currentVersion) {
          await sendVersionLog(currentVersion);
          localStorage.setItem("appVersion", currentVersion);
          console.log("Version changed, log sent.");
        }

        if (storedVersion && storedVersion !== currentVersion) {
          // Force a hard refresh by modifying the URL to bypass cache
          window.location.href = window.location.pathname + "?v=" + new Date().getTime();
          console.log("Refreshed because of new version");
        }
      } catch (error) {
        console.error("Version check failed", error);
      }
    };

    // Run immediately and set an interval for future checks
    checkForUpdate();
    const interval = setInterval(checkForUpdate, 60000 * 5); // Check every 5 minutes

    return () => clearInterval(interval);
  }, []);

  return null;
}

export default useVersionChecker;
