import { useEffect, useState } from "react";
import axios from "axios";
import { checkOrderURL } from "../services/ApiService";
import "./css/TrackOrder.css";
import React from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../services/axiosInstance";

function TrackOrder() {
	const [orderData, setOrderData] = useState(null);
	const [error, setError] = useState(null);
	  const { t } = useTranslation();

	  useEffect(() => {
		const fetchOrderData = async () => {
			try {
				const dealersOrdersId = localStorage.getItem("customerid");
				const trackorderURL = `${checkOrderURL}${dealersOrdersId}`;
				const response = await axiosInstance.get(trackorderURL);
	
				const rezultati = response.data.map((item) => ({
					id_dealersorders:item.id_dealersorders,
					quantity: item.quantity,
					price: item.price,
					total: item.total,
					quantity_sent: item.quantity_sent,
					quantity_due: item.quantity_due * -1,
					productname: item.productname,
					datetimesubmited: item.datetimesubmited,
					operator: item.opr_name,
					oemnr: item.upcsku,
					last_price: item.last_price,
					total_to_pay: item.total_to_pay,
					vendornr: item.vendoritemnumber,
				}));
				setOrderData(rezultati);
			} catch (err) {
				setError("Failed to fetch order data.");
				console.error(err);
			}
		};
		fetchOrderData()
		/*
	
		let intervalId;
	
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				// Start fetching data at regular intervals when the page is active
				fetchOrderData(); // Fetch data immediately
				intervalId = setInterval(fetchOrderData, 6000); // Fetch every 1 minute
			} else {
				// Clear interval when the page is inactive
				clearInterval(intervalId);
			}
		};
	
		// Set up the visibility change listener
		document.addEventListener("visibilitychange", handleVisibilityChange);
	
		// Run the effect initially if the page is visible
		if (document.visibilityState === "visible") {
			handleVisibilityChange();
		}
	
		// Cleanup
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			clearInterval(intervalId);
		};
		*/
	}, []);
	
//------------------alert if quantity sent and due not the same
/*
useEffect(() => {
	// Check if orderData exists and has items
	if (orderData && orderData.length > 0) {
		// Iterate through orderData to check quantity_sent !== quantity_due
		orderData.forEach((item) => {
			if (item.quantity_sent !== item.quantity) {
				alert(`Alert: Quantity Sent (${item.quantity_sent}) is not equal to Quantity Due (${item.quantity}).`);
			}
		});
	}
}, [orderData]);
*/

	return (
		<div className="track-orders-container">
			<h2 className="track-orders-title">{t("trackorders")}</h2>
			{Array.isArray(orderData) ? (
				<div className="order-card">
					<table className="order-table">
						<thead>
							<tr>
							<th>{t("dealersordersid")}</th>
								<th>{t("produktname")}</th>
								<th>{t("oemnumber")}</th>
								<th>{t("articlenumber")}</th>
								<th>{t("price")}</th>
								<th>{t("quantity")}</th>
								<th>{t("pricesum")}</th>
								<th>{t("QuantitySent")}</th>
								<th>{t("lastprice")}</th>
								<th>{t("totaltopay")}</th>
								<th>{t("QuantityDue")}</th>
								<th>{t("DateSubmitted")}</th>
								<th>{t("operator")}</th>
							</tr>
						</thead>
						<tbody>
							{orderData.map((rezultati, index) => (
								<tr key={index}>
									<td>{rezultati.id_dealersorders}</td>
									<td>{rezultati.productname}</td>
									<td>{rezultati.oemnr}</td>
									<td>{rezultati.vendornr}</td>
									<td className="cmimitrackorder">{rezultati.price}</td>
									<td className="sasitettrackorder">{rezultati.quantity}</td>
									<td className="cmimitrackorder">{rezultati.total}</td>
									<td className="sasitettrackorder">{rezultati.quantity_sent}</td>
									<td className="lastprice">{rezultati.last_price}</td>
									<td className="totaltopay">{rezultati.total_to_pay}</td>
									<td className="sasitettrackorder"
										style={{
											color:
												rezultati.quantity_due < 0
													? "red"
													: rezultati.quantity_due > 0
													? "green"
													: "inherit", // No color change for 0
											fontWeight: "bold",
										}}
									>
										{rezultati.quantity_due}
									</td>

									<td>
										{rezultati.datetimesubmited
											? new Date(rezultati.datetimesubmited)
													.toISOString()
													.slice(0, 10)
													.split("-")
													.reverse()
													.join(".")
											:` ${t("stillincart")}`}
									</td>
									<td>{rezultati.operator}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<p className="track-orders-loading">Loading or no data available</p>
			)}
		</div>
	);
}

export default TrackOrder;
