import React, { useEffect } from "react";
import { useCartContext } from "../contexts/CartContext";
import "./css/shopingCartPage.css";
import { checkOutCart, updateItemAndUpdateCart } from "../contexts/CartContextFunctions";
import { useTranslation } from "react-i18next";

function ShopingCartPage() {
  const { t } = useTranslation();
  const {
    cartItems,
    handleRemoveItem,
    totalShuma,
    pagesaMenyra,
    dergesaMenyra,
    setPagesaMenyra,
    setDergesaMenyra,
  } = useCartContext();

  // Function to increment item quantity in the cart
  const handleIncrementQuantityCart = (index) => {
    const updatedCart = [...cartItems];
    updatedCart[index].quantity += 1;
    updatedCart[index].shuma =
      updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
    const idToUpdate = updatedCart[index].id;
    const quantityToUpdate = updatedCart[index].quantity;
    updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
  };

  // Function to decrement item quantity in the cart
  const handleDecrementQuantityCart = (index) => {
    const updatedCart = [...cartItems];
    if (updatedCart[index].quantity === 1) return;
    updatedCart[index].quantity -= 1;
    updatedCart[index].shuma =
      updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
    const idToUpdate = updatedCart[index].id;
    const quantityToUpdate = updatedCart[index].quantity;
    updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
  };

  // Function to manually update item quantity in the cart
  const handleAddManualQuantityCart = (index, quantity) => {
    const updatedCart = [...cartItems];
    updatedCart[index].quantity = parseInt(quantity, 10) || 0;
    updatedCart[index].shuma =
      updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
    const idToUpdate = updatedCart[index].id;
    const quantityToUpdate = updatedCart[index].quantity;
    updateItemAndUpdateCart(idToUpdate, quantityToUpdate);
  };

  // Function to proceed to checkout
  const checkOut = () => {
    checkOutCart(pagesaMenyra, dergesaMenyra);
  };

  return (
    <div className="fullpage-cart-container">
      <header className="cart-header">
        <h1>{t("yourshopingcart")}</h1>
      </header>
      <main className="cart-content">
        <div className="cart-layout">
          {/* Cart Items List */}
          <section className="cart-items-section">
            {cartItems.length === 0 ? (
              <div className="empty-cart-message">
                <p>{t("yourcartempty")}</p>
              </div>
            ) : (
              <>
                <h2>{t("cartitems")}</h2>
                <div className="cart-items-list">
                  {cartItems.map((item, index) => (
                    <div key={index} className="cart-item">
                      <div className="cart-item-info">
                        <h5>{item.produkt_name}</h5>
                        <p>{item.brand}</p>
                        <p>
                          <strong>{t("priceforone")}:</strong> {item.price}
                        </p>
                      </div>
                      <div className="cart-item-actions">
                        <div className="quantity-control">
                          <button
                            onClick={() => handleDecrementQuantityCart(index)}
                            disabled={item.quantity <= 1}
                            className="quantity-btn"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={item.quantity}
                            onChange={(e) =>
                              handleAddManualQuantityCart(index, e.target.value)
                            }
                            className="quantity-input"
                          />
                          <button
                            onClick={() => handleIncrementQuantityCart(index)}
                            className="quantity-btn"
                          >
                            +
                          </button>
                        </div>
                        <p className="cart-item-total">
                          <strong>{t("pricesum")}:</strong> {item.shuma}
                        </p>
                        <button
                          onClick={() => handleRemoveItem(index)}
                          className="remove-btn"
                        >
                          {t("remove")}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </section>
          {/* Checkout Details */}
          <aside className="checkout-details-section">
            <h2>{t("checkoutdetails")}</h2>
            <div className="method-card">
              <h5>{t("paymentmethod")}</h5>
              <select
                value={pagesaMenyra}
                onChange={(e) => setPagesaMenyra(e.target.value)}
                className="method-select"
              >
                <option value="Кеш">{t("cash")}</option>
                <option value="Фактура">{t("invoice")}</option>
                <option value="Со откуп">{t("sootkup")}</option>
              </select>
            </div>
            <div className="method-card">
              <h5>{t("deliverymethod")}</h5>
              <select
                value={dergesaMenyra}
                onChange={(e) => setDergesaMenyra(e.target.value)}
                className="method-select"
              >
                <option value="Самоподигање">{t("pickup")}</option>
                <option value="Такси / Комби">{t("taksikombi")}</option>
                <option value="Бус">{t("bus")}</option>
                <option value="Достава">{t("delivery")}</option>
                <option value="Карго">{t("cargo")}</option>
              </select>
            </div>
            <div className="checkout-section">
              <p className="total-price">
                {t("totalsuma")}: {totalShuma}
              </p>
              <button onClick={checkOut} className="checkout-btn">
                {t("checkout")}
              </button>
            </div>
          </aside>
        </div>
      </main>
    </div>
  );
}

export default ShopingCartPage;